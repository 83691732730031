<template>
  <div class="fitment-classsfication">
    <van-nav-bar
      title="商品类目"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    />

    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in classificationList"
          :key="index"
          :title="item.name"
          clickable
          @click="cellClick(item)"
        >
          <template #right-icon>
            <van-radio :name="item.id" :disabled="item.lock" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
import { getClassification, unlock } from "@/api/fitment";
export default {
  name: "fitmentClassification",
  data() {
    return {
      classificationList: [],
      radio: null,
      name: null,
      index: null,
    };
  },
  created() {
    this.index = this.$route.query.index;
    this.getClassification();
  },
  methods: {
    // 获取分类
    getClassification() {
      getClassification().then((response) => {
        getClassification({ size: response.data.data.total }).then((res) => {
          console.log(res);
          const result = res.data.data;
          result.records.map((x) => {
            x.lock = true;
            this.$store.state.fitment.unlockedCates.map((i) => {
              if (i.unlockId === x.id) {
                x.lock = false;
              }
            });
          });
          this.classificationList = result.records;
          // this.radio = result.records[0].id;
          // this.name = result.records[0].name;
        });
      });
    },
    cellClick(item) {
      if (item.lock) {
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "是否支付" + item.expense + "创客金币开通" + item.name + "分类？",
          })
          .then(() => {
            console.log("confirm");
            unlock({
              unlockType: "2", // 写死
              unlockId: item.id,
              // modulesType: this.modulesType,
              gold: item.expense,
            }).then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$toast("解锁成功");
                this.$store.dispatch("getUnlockedCates").then(() => {
                  this.getClassification();
                });
              }
            });
          })
          .catch(() => {});
      } else {
        this.radio = item.id;
        this.name = item.name;
      }
    },
    complete() {
      console.log("complete");
      this.$store.commit("chooseLink", {
        type: "classification",
        index: this.index,
        value: {
          id: this.radio,
          name: this.name,
        },
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-classsfication {
  background-color: #f6f6f6;
}
</style>